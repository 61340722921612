import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {BlockSolid} from "./components/BlockSolid";
import {useAuth} from "../../modules/auth";
import {useEffect, useState} from "react";
import {getDashboardData} from "./core/_requests";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {BlockAffiliateCard} from "./components/BlockAffiliateCard";

const DashboardPage = () => {
  const intl = useIntl()
  const { currentProperty } = useAuth();
  const [dashboardData, setDashboardData] = useState<any>(null)

  useEffect(() => {
    if (!dashboardData) {
      getDashboardData(currentProperty?.property_id)
        .then(({data}) => setDashboardData(data))
    }
  }, [dashboardData])

  const areDiscountsEnabled = currentProperty?.card_convenzione_estay_manual || currentProperty?.card_convenzione_estay_kross
  const intlAffiliatedLabel = areDiscountsEnabled ? 'ACTIVE' : 'INACTIVE'

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col'>
          <BlockAffiliateCard
            className='card-xl-stretch mb-5 mb-xl-8'
            title={intl.formatMessage({id: `DASHBOARD.BLOCKS.DISCOUNT_CARD.${intlAffiliatedLabel}.TITLE`})}
            subtitle={intl.formatMessage({id: `DASHBOARD.BLOCKS.DISCOUNT_CARD.${intlAffiliatedLabel}.SUBTITLE`})}
            callToAction={intl.formatMessage({id: `DASHBOARD.BLOCKS.DISCOUNT_CARD.VIEW_PARTNERS`})}
            backgroundImage={toAbsoluteUrl('/media/svg/illustrations/sigma/illustration-realestate.svg')}
            areDiscountsEnabled={areDiscountsEnabled}
            uuid={currentProperty?.uuid}
            date_arrival={currentProperty?.date_arrival}
            date_leaving={currentProperty?.date_leaving}
            reservation_code={currentProperty?.reservation_code}
          />
        </div>
      </div>

      {currentProperty?.house_manual_visible ? (
        <>
          <div className='row g-5 g-xl-8'>
            <div className='col-12'>
              <BlockSolid
                className='card-xxl-stretch bg-warning mb-5 mb-xl-8'
                svgIcon='/media/icons/duotune/electronics/elc007.svg'
                titleClass='text-white'
                descriptionClass='text-white'
                iconClass='svg-icon-white'
                value={dashboardData?.wifi?.password}
                description={dashboardData?.wifi?.ssid || intl.formatMessage({id: 'DASHBOARD.BLOCKS.WIFI'})}
                stacked={false}
                label={"Wi-Fi"}
              />
            </div>
            <div className="col-6 mt-0">
              <BlockSolid
                className='card-xxl-stretch bg-primary mb-5 mb-xl-8 text-center'
                titleClass='text-white'
                descriptionClass='text-white'
                iconClass='svg-icon-white'
                value={intl.formatMessage({id: 'DASHBOARD.BLOCKS.GMAPS.CLICK_ME'})}
                link={dashboardData?.gmaps?.link}
                description={intl.formatMessage({id: 'DASHBOARD.BLOCKS.GMAPS'})}
                pngIcon="/media/estay/card-icons-maps.png"
              />
            </div>

            <div className="col-6 mt-0">
              <BlockSolid
                className='card-xxl-stretch bg-primary mb-5 mb-xl-8 bg-primary text-center'
                titleClass='text-white'
                descriptionClass='text-white'
                iconClass='svg-icon-white'
                link={`/p/${currentProperty?.property_id}/r`}
                description={intl.formatMessage({id: 'DASHBOARD.BLOCKS.ACCESS.TITLE'})}
                value={intl.formatMessage({id: 'DASHBOARD.BLOCKS.ACCESS.SUBTITLE'})}
                pngIcon="/media/svg/illustrations/sigma/illustration-realestate.svg"
              />
            </div>

            <div className='col-12 mt-0'>
              <BlockSolid
                className='card-xxl-stretch bg-warning mb-5 mb-xl-8'
                titleClass='text-white'
                descriptionClass='text-white'
                iconClass='svg-icon-white'
                description={intl.formatMessage({id: 'DASHBOARD.BLOCKS.EMERGENCY.TITLE'})}
                value={intl.formatMessage({id: 'DASHBOARD.BLOCKS.EMERGENCY.SUBTITLE'})}
                stacked={false}
                pngIcon="/media/estay/card-icons-imprevisti.png"
              />
            </div>

            <div className="col-6 mt-0">
              <BlockSolid
                className='card-xxl-stretch bg-primary mb-5 mb-xl-8 bg-primary text-center'
                titleClass='text-white'
                descriptionClass='text-white'
                iconClass='svg-icon-white'
                value="Domande Frequenti"
                link={`/p/${currentProperty?.property_id}/r`}
                pngIcon="/media/estay/card-icons-faq.png"
              />
            </div>

            <div className="col-6 mt-0">
              <BlockSolid
                className='card-xxl-stretch bg-primary mb-5 mb-xl-8 bg-primary text-center'
                titleClass='text-white'
                descriptionClass='text-white'
                iconClass='svg-icon-white'
                value="Manuale dotazione casa"
                link={`/p/${currentProperty?.property_id}/r`}
                pngIcon="/media/estay/card-icons-house-manual.png"
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
