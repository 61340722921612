import {QRCodeCanvas, QRCodeSVG} from "qrcode.react";
import React from "react";

type Props = {
  value: string,
  documentId: string
  size?: number
}
const QRGenerator: React.FC<Props> = ({
  value,
  documentId,
  size = 160
}) => {
  return (
    <div>
      <QRCodeCanvas
        id={documentId}
        value={value}
        size={size}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"H"}
      />
    </div>
  );
}

export default QRGenerator
