/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {AsideMenu} from './AsideMenu'
import {useIntl} from "react-intl";
import {useAuth} from "../../../../app/modules/auth";
import {KTSVG} from "../../../helpers";

const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const {aside} = config
  const intl = useIntl()
  const { logout, currentAffiliate } = useAuth()

  if (!!currentAffiliate) return null
  return (
    <div
      id='kt_aside'
      className={clsx('aside card aside-dark aside-hoverable', classes.aside.join(' '), {'d-none': !aside.display})}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      <div className="app-sidebar-footer menu flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
        <a onClick={logout} className='py-4 menu-link menu-title cursor-pointer text-dark me-5'>
          <KTSVG
            className='svg-icon-2'
            path='/media/icons/duotune/arrows/arr064.svg'
          />
          {intl.formatMessage({id: 'AUTH.GENERAL.SIGNOUT_BUTTON'})}
        </a>
      </div>
      {/* end::Aside menu */}
    </div>
  )
}

export {AsideDefault}
