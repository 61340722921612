/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {FC} from "react";

type Props = {
  className?: string
  svgIcon?: string
  titleClass?: string
  descriptionClass?: string
  iconClass?: string
  value?: string
  description?: string
  link?: string
  stacked?: boolean,
  label?: string,
  pngIcon?: string
}
const BlockSolid: FC<Props> = ({
   className,
   svgIcon,
   titleClass,
   descriptionClass,
   iconClass,
   value,
   description,
   link,
   stacked = true,
   label = '',
   pngIcon
 }) => {
  let href = link ? link : '#'

  if (label === 'Wi-Fi') {
    href = `WIFI:T:WPA;S:${description};T:WPA2;P:${value};;`
    // href = `wifi://:${value}@${description}`
  }
  return (
    <a href={href} className={clsx('card', className)}>

      <div className='card-body d-flex flex-column justify-content-between align-middle flex-grow-1'>
        {stacked ? (
          <>
            {svgIcon && (
              <div className="symbol symbol-badge">
                <KTSVG
                  path={svgIcon}
                  className='svg-icon-2'
                />
              </div>
            )}

            {pngIcon && (
              <div className="flex justify-content-center w-120">
                <img
                  alt='Pic'
                  src={toAbsoluteUrl(pngIcon)}
                  style={{
                    width: '120px',
                    height: '120px'
                  }}
                />
              </div>
            )}

            <div className='d-flex flex-column'>
              <div className={clsx(titleClass, 'fw-semibold fs-1 mb-0 mt-5')}>
                {description}
              </div>

              <div className={clsx(descriptionClass, 'fw-bold fs-3')}>
                {value}
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-4 col-md-2 text-center">
              {label && <h4 className="text-white">{label}</h4>}

              {svgIcon && <KTSVG path={svgIcon || ''} className={clsx(iconClass, 'svg-icon-2hx ms-n1 flex-grow-1')} />}
              {pngIcon && (
                <img
                  alt='Pic'
                  src={toAbsoluteUrl(pngIcon)}
                  className="w-100"
                />
              )}
            </div>
            <div className="col-8 col-md-10">
              <div className={clsx(titleClass, 'fw-semibold fs-1 mb-0 mt-5')}>
                {description}
              </div>

              <div className={clsx(descriptionClass, 'fw-bold fs-3')}>
                {value}
              </div>
            </div>
          </div>
        )}
      </div>
    </a>
  )
}

export {BlockSolid}
