/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from "react-router-dom";
import QRGenerator from "./QRGenerator";
import {KTSVG} from "../../../../_metronic/helpers";
import React from "react";
import {useIntl} from "react-intl";
import { PDFDownloadLink } from "@react-pdf/renderer";
import QRCodeDocument from "./QRCodeDocument";

type Props = {
  className?: string
  bgColor?: string
  title?: string
  subtitle?: string,
  callToAction?: string
  link?: string
  backgroundImage?: string
  backgroundPosition?: string
  areDiscountsEnabled?: boolean
  uuid?: string,
  date_arrival?: string
  date_leaving?: string
  reservation_code?: string
}
const BlockAffiliateCard = ({
  className,
  title = 'Create SaaS',
  subtitle = 'Based Reports',
  callToAction = 'Create Campaign',
  backgroundPosition = 'right',
  link = '/partners',
  areDiscountsEnabled = false,
  uuid,
  date_arrival= '',
  date_leaving= '',
  reservation_code ='',
}: Props) => {
  const intl = useIntl()
  const discountStatus = areDiscountsEnabled ? 'ACTIVE' : 'INACTIVE'

  const mailText = encodeURI(`${intl.formatMessage({ id: 'DASHBOARD.BLOCKS.DISCOUNT_CARD.INACTIVE.EMAIL'})} ${reservation_code}`)
  const whatsappText = encodeURI(`${intl.formatMessage({ id: 'DASHBOARD.BLOCKS.DISCOUNT_CARD.INACTIVE.WHATSAPP_TEXT'})} ${reservation_code}`)
  return (
    <div
      className={clsx('card h-185px bgi-no-repeat bgi-size-contain bg-white border border-4 border-primary', className)}
      style={{
        // backgroundColor: bgColor,
        backgroundPosition: backgroundPosition,
        // backgroundImage: `url("${backgroundImage}")`,
      }}
    >
      <div className={"card-body pt-0 d-flex flex-column justify-content-center card-stretch"}>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ padding: '20px 0 0 0' }}>
          <div className="p-2">
            <img
              src='/media/estay/estay-city-pass-logo.png'
              style={{ width: 150}}
            />
          </div>

          <div
            className={`p-2 text-${areDiscountsEnabled ? 'success' : 'danger' }`}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: `DASHBOARD.BLOCKS.DISCOUNT_CARD.STATUS.${discountStatus}`
              })
            }}
            style={{ fontSize: '22px', fontWeight: 'bold'}}
          />
        </div>

        <div>
          <h4
            className="m-8 text-center text-primary"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>

        <div className='d-flex flex-column justify-content-center card-stretch'>
          <div className={` ${backgroundPosition === 'left' ? 'offset-6' : ''}`}>
            {areDiscountsEnabled && (
              <div className={`col-12 text-center text-primary`}>
                <QRGenerator
                  value={`https://house.estay.it/affiliates/card/${uuid}`}
                  documentId={`uuid-${uuid}`}
                />
              </div>
            )}
          </div>

          {areDiscountsEnabled && (
            <div className={`text-center my-8`} style={{ fontSize: 12 }}>
              Validity: {date_arrival} - {date_leaving}
            </div>
          )}

          <div className={`d-flex justify-content-${areDiscountsEnabled ? 'between' : 'center'}`}>
            <Link
              to={link}
              className='btn btn-primary btn-lg border border-2 border-primary'
              style={{ fontSize: 16, fontWeight: 'bold' }}
            >
              {callToAction}
            </Link>

            {areDiscountsEnabled && uuid && (
              <PDFDownloadLink
                document={
                  <QRCodeDocument
                    url={`https://house.estay.it/affiliates/card/${uuid}`}
                    ids={[`uuid-${uuid}`]}
                    text={intl.formatMessage({id: 'DASHBOARD.BLOCKS.DISCOUNT_CARD.PDF.TITLE'})}
                    subtext={`Validity: ${date_arrival} - ${date_leaving}`}
                  />
                }
                fileName="estay_card.pdf"
              >
                {({ loading }) => (loading ? "Loading..." : (
                  <button
                    className="btn btn-outline-primary border border-2 border-primary btn-lg"
                    style={{ fontSize: 16, fontWeight: 'bold', color: '#706f6f' }}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to download the QR'
                  >
                    Wallet
                  </button>
                ))}
              </PDFDownloadLink>
            )}
          </div>


          {!areDiscountsEnabled && (
            <>
              <div className="d-flex justify-content-center">
                <h2
                  className="m-8 text-center text-primary"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              </div>

              <div className="d-flex justify-content-between">
                <a
                  className="btn btn-outline-primary btn-lg border border-2 border-primary"
                  style={{ border: '1px solid'}}

                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'

                  title='Click to download the QR'
                  href={`https://wa.me/+390708006928?text=${whatsappText}`}
                >
                  <i className="bi bi-whatsapp text-success"
                     style={{fontSize: '1.5rem'}}
                  />

                  Whatsapp
                </a>

                <a
                  href={`mailto:booking@estay.it?subject=${mailText}`}
                  className="btn btn-outline-primary btn-lg border border-2 border-primary"
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Click to download the QR'
                >
                  <KTSVG
                    path='/media/icons/duotune/art/art005.svg'
                    className='svg-icon-3 svg-icon-dark'
                  />

                  {intl.formatMessage({
                    id: `DASHBOARD.BLOCKS.DISCOUNT_CARD.INACTIVE.CALL_TO_ACTION`
                  })}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export {BlockAffiliateCard}
