import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {AffiliateCardReadingResponse, ScanTrackerReadingResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const AFFILIATES_URL = `${API_URL}/affiliates`
const CARD_URL = `${AFFILIATES_URL}/card`

const getReservationData = (reservationUuid: ID): Promise<AffiliateCardReadingResponse> => {
  return axios
    .get(`${CARD_URL}/${reservationUuid}`)
    .then((d: AxiosResponse<AffiliateCardReadingResponse>) => d.data)
}

const getHistoricalScansData = (): Promise<ScanTrackerReadingResponse> => {
  return axios
    .get(`${AFFILIATES_URL}/scans`)
    .then((d: AxiosResponse<ScanTrackerReadingResponse>) => d.data)
}

export {getReservationData, getHistoricalScansData}
