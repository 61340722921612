import axios from 'axios'
import {AffiliateMetadata, AuthModel, PropertyMetadata} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const AUTH_URL = `${API_URL}/auth`

export const VERIFY_TOKEN_URL = `${AUTH_URL}/verify_token`
export const LOGIN_URL = `${AUTH_URL}/login`
export const LOGIN_AFFILIATE_URL = `${AUTH_URL}/affiliates/login`

// Server should return AuthModel
export function login(reservationCode: string, dayOfArrival: number, monthOfArrival: number) {
  return axios.post<AuthModel>(LOGIN_URL, {
    reservationCode,
    dayOfArrival,
    monthOfArrival
  })
}

export function loginAffiliate(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_AFFILIATE_URL, {
    email,
    password,
  })
}

export function getMetadataByToken(token: string) {
  return axios.post<PropertyMetadata | AffiliateMetadata>(VERIFY_TOKEN_URL, {
    api_token: token,
  })
}
