import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useEffect, useState} from "react";
import {getReservationData} from "./core/_requests";
import {ID, KTSVG} from "../../../_metronic/helpers";
import {useParams} from "react-router-dom";
import clsx from "clsx";
import {QueryRequestProvider} from "./core/QueryRequestProvider";
import {QueryResponseProvider, useQueryResponseData} from "./core/QueryResponseProvider";
import {useLang} from "../../../_metronic/i18n/Metronici18n";

const AffiliateCardReadingPage = () => {
  const [dashboardData, setDashboardData] = useState<any>(null)
  // const [scans, setScans] = useState<any>([])
  const { uuid } = useParams()
  const language = useLang()

  const data: any = useQueryResponseData()
  const scans = data?.scans

  console.log('scans', scans)

  useEffect(() => {
    if (!dashboardData) {
      getReservationData(uuid as ID)
        .then(({data}) => setDashboardData(data))
    }
  }, [dashboardData])

  const message = dashboardData && dashboardData.is_enabled ? 'CARD VALIDA' : 'CARD NON VALIDA'

  return (
    <div
      className={clsx(`card h-185px bgi-no-repeat bgi-size-contain p-5`)}
    >
      <h1 className="text-primary text-center bold">
        Storico Scansioni
      </h1>

      <p className="text-xl" style={{ fontSize: 16 }}>
        In questa pagina potrai vedere gli scan effettuati in passato, in arrivo nelle prossime versioni
      </p>

      <table className="table">
        <thead>
        <tr>
          <th scope="col">Città Ospite</th>
          <th scope="col">Numero Ospiti</th>
          <th scope="col">Data Scan</th>
        </tr>
        </thead>
        <tbody>
        {scans && scans.length && scans.map((scan: any, index: number) => {
          const created_at = new Date(scan.created_at)
            .toLocaleDateString(language, {
              weekday:"long",
              year:"numeric",
              month:"short",
              day:"numeric"
            })
          return (
            <tr>
              <td>{scan.guest_city}</td>
              <td>{scan.guests_count}</td>
              <td>{created_at}</td>
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  );
}

const AffiliateHowItWorksWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      <QueryRequestProvider>
        <QueryResponseProvider>
          <AffiliateCardReadingPage />
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {AffiliateHowItWorksWrapper}
