/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {getMetadataByToken, loginAffiliate} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {useIntl} from "react-intl";
import {AffiliateMetadata} from "../core/_models";

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginAffiliate() {
  const intl = useIntl()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({
        id: 'AUTH.LOGIN.EMAIL.ERRORS.REQUIRED'
      }))
      .required(intl.formatMessage({
        id: 'AUTH.LOGIN.EMAIL.ERRORS.REQUIRED'
      })),
    password: Yup.string()
      .required(intl.formatMessage({
        id: 'AUTH.LOGIN.PASSWORD.ERRORS.REQUIRED'
      })),
  })

  const initialValues = {
    email: '',
    password: '',
  }

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentAffiliate} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const { email, password } = values

      try {
        const {data: auth} = await loginAffiliate(email, password)
        saveAuth(auth)
        const {data: affiliate} = await getMetadataByToken(auth.api_token)
        setCurrentAffiliate(affiliate as AffiliateMetadata)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_affiliate_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}
        </h1>
        <div className='text-gray-400 fw-semibold fs-4'>
          {intl.formatMessage({id: 'AUTH.LOGIN.AFFILIATES.SUBTITLE'})}
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            {intl.formatMessage({id: 'AUTH.LOGIN.AFFILIATES.INSTRUCTIONS'})}
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bold text-dark'>
          {intl.formatMessage({id: 'AUTH.LOGIN.EMAIL'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.LOGIN.EMAIL'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='on'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='row mb-10'>
        <div className="col">
          <label className='form-label fs-6 fw-bold text-dark'>
            {intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD'})}
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            type='password'
            name='password'
            autoComplete='on'
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
          </span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'LAYOUT.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
