import {Image, Page, StyleSheet, Text, View, Font } from "@react-pdf/renderer";
import React from "react";

Font.register({
  family: 'Josefin Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/josefinsans/v26/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQXMFrLgTsQV0.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/josefinsans/v26/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQXMFrLgTsQV0.ttf',
      fontWeight: 500,
    }
  ]
});

Font.register({
  family: 'Inter',
  src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
      fontWeight: 100,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
      fontWeight: 200,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
      fontWeight: 700,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
      fontWeight: 800,
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
      fontWeight: 900,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    // @ts-ignore
    // display: "block",
    backgroundColor: '#ffffff',
    border: '4pt solid #1c96b6'
  },
  view: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%"
  },
  qrImage: {
    width: "100px",
    height: "100px",
  },
  logoCityPass: {
    width: "80px",
    height: "auto",
  },
  message: {
    color: "#1c96b6",
    fontFamily: "Josefin Sans",
  }
});
// @ts-ignore
function QRCodePage({ id, url, text, subtext }) {
  if (!id || !url) return null
  // @ts-ignore
  const dataUrl = document.getElementById(id).toDataURL();

  return (
    <Page size="B8" style={styles.page}>
      <View style={styles.view}>
        {/*// @ts-ignore*/}
        <Image allowDangerousPaths src={"/media/estay/logo-city-pass-white.png"} style={styles.logoCityPass} />
      </View>

      <View style={styles.view}>
        {/*// @ts-ignore*/}
        <Image allowDangerousPaths src={dataUrl} style={styles.qrImage} />
      </View>

      <View style={styles.view}>
        <Text style={{ ...styles.message, fontSize: 8, padding: 12, color: '#706f6f' }}>
          {subtext}
        </Text>
      </View>

      <View style={styles.view}>
        <Text style={{ ...styles.message, fontSize: 10, padding: '4px 12px', textAlign: 'center' }}>
          {text}
        </Text>
      </View>
    </Page>
  );
}

export default QRCodePage
