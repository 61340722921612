/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useLayout} from '../core'
import {KTSVG} from "../../helpers";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";

const FooterFixedPartners: FC = () => {
  const {classes} = useLayout()
  const { pathname } = useLocation()
  const [currentPathname, setCurrentPathname] = useState('home')

  console.log('pathname', pathname)

  useEffect(() => {
    switch (pathname) {
      case '/dashboard':
        setCurrentPathname('dashboard')
        break
      case '/come-funziona':
        setCurrentPathname('come-funziona')
        break
      default:
        setCurrentPathname('dashboard')
    }
  }, [pathname])


  return (
    <nav className="navbar fixed-bottom text-black bg-white d-block d-lg-none h-60px align-items-center">
      <div className="d-flex flex-row justify-content-evenly align-items-center">
        <Link to="/dashboard" className="p-2 flex-fill text-center align-self-center cursor-pointer">
          <KTSVG
            path='/media/estay/home.svg'
            className={`svg-icon-2x svg-icon-gray-400 mt-1 text-${currentPathname === 'dashboard' ? 'primary' : 'gray-500'}`}
          />
        </Link>

        <Link to="/come-funziona" className="p-2 flex-fill text-center align-self-center cursor-pointer">
          <KTSVG
            path='/media/estay/icon-qr-code.svg'
            className={`svg-icon-3x svg-icon-gray-400 mt-1 text-${currentPathname === 'come-funziona' ? 'primary' : 'gray-500'}`}
          />
        </Link>

        {/*<Link to="/contatti" className="p-2 flex-fill text-center align-self-center cursor-pointer">*/}
        {/*  <i*/}
        {/*    className='fas fa-question svg-icon-2x ms-2 fs-2hx'*/}
        {/*    data-bs-toggle='tooltip'*/}
        {/*    title='Provide your team size to help us setup your billing'*/}
        {/*  ></i>*/}
        {/*</Link>*/}
      </div>
    </nav>
  )
}

export {FooterFixedPartners}
