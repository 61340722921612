/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {
  ThemeModeSwitcher,
} from '../../../partials'
import { HeaderLanguagesMenu } from "../../../partials/layout/header-menus/HeaderLanguagesMenu";
import {useAuth} from "../../../../app/modules/auth";

const toolbarButtonMarginClass = 'ms-1 ms-lg-3'
const toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p'

const Topbar: FC = () => {
  const { logout, currentAffiliate } = useAuth()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* begin::Theme mode */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        {/* end::Theme mode */}

        {/* begin::Languages */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_languages_menu_toggle'
        >
          {/* begin::Toggle */}

          <HeaderLanguagesMenu />
          {/* end::Toggle */}
        </div>
        {/* end::Languages */}

        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <button className="btn btn-primary" onClick={logout}>Logout</button>
        </div>
      </div>
    </div>
  )
}

export {Topbar}
