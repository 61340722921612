import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import React, {useEffect, useState} from "react";
import {getReservationData} from "./core/_requests";
import {ID, KTSVG} from "../../../_metronic/helpers";
import {useParams} from "react-router-dom";
import clsx from "clsx";

const AffiliateCardReadingPage = () => {
  const [dashboardData, setDashboardData] = useState<any>(null)
  const { uuid } = useParams()

  useEffect(() => {
    if (!dashboardData) {
      getReservationData(uuid as ID)
        .then(({data}) => setDashboardData(data))
    }
  }, [dashboardData])

  const message = dashboardData && dashboardData.is_enabled ? 'CARD VALIDA' : 'CARD NON VALIDA'

  return (
    <div
      className={clsx(`card h-185px bgi-no-repeat bgi-size-contain`)}
    >
      <div className='card-body d-flex flex-column justify-content-between card-stretch'>
        <div className="row">
          <div className={`col`}>
            <h1 className={`text-center ${dashboardData && dashboardData.is_enabled ? 'text-success' : 'text-danger'}`}>
              {message}
            </h1>

            {dashboardData && dashboardData.is_enabled ? (
              <>
                {dashboardData?.discount && (
                  <>
                    <h3 className="text-success text-center">
                      Applicare i seguenti sconti:
                    </h3>
                    <div className="d-flex flex-row text-gray-900 rounded align-items-center pt-4 mt-8 mb-12">
                      <div className="p-2">
                        <KTSVG path="/media/icons/duotune/ecommerce/ecm012.svg" className={'svg-icon svg-icon-4x mx-2'}/>
                      </div>
                      <h3 dangerouslySetInnerHTML={{ __html: dashboardData.discount }}></h3>
                    </div>
                  </>
                )}
              </>
            ) : ''}
          </div>
        </div>
      </div>
    </div>
  );
}

const AffiliateCardReadingWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <AffiliateCardReadingPage />
    </>
  )
}

export {AffiliateCardReadingWrapper}
