/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useLayout} from '../core'
import {KTSVG} from "../../helpers";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";

const FooterFixedGuests: FC = () => {
  const {classes} = useLayout()
  const { pathname } = useLocation()
  const [currentPathname, setCurrentPathname] = useState('dashboard')

  useEffect(() => {
    console.log('pathname', pathname)
    switch (pathname) {
      case '/dashboard':
        setCurrentPathname('dashboard')
        break
      default:
        setCurrentPathname('partners')
    }
  }, [pathname])
  return (
    <nav className="navbar fixed-bottom text-black bg-white d-block d-lg-none h-60px align-items-center">
      <div className="d-flex flex-row justify-content-evenly align-items-center">
        <Link to="dashboard" className="p-2 flex-fill text-center align-self-center cursor-pointer">
          <KTSVG
            path='/media/estay/home.svg'
            className={`svg-icon-2x svg-icon-gray-400 mt-1 text-${currentPathname === 'dashboard' ? 'primary' : 'gray-500'}`}
          />
        </Link>

        <Link to="/partners" className="p-2 flex-fill text-center align-self-center cursor-pointer">
          <KTSVG
            path='/media/estay/activities.svg'
            className={`svg-icon-2x svg-icon-gray-400 mt-1 text-${currentPathname === 'partners' ? 'primary' : 'gray-500'}`}
          />
        </Link>

        {/*<div className="p-2 flex-fill text-center align-self-center cursor-pointer">*/}
        {/*  <KTSVG*/}
        {/*    path='/media/estay/magnifier.svg'*/}
        {/*    className={`svg-icon-2x svg-icon-gray-400 mt-1 text-${currentPathname === 'CHANGE_ME' ? 'primary' : 'gray-500'}`}*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<Link to="#" className="p-2 flex-fill text-center align-self-center cursor-pointer">*/}
        {/*  <KTSVG*/}
        {/*    path='/media/estay/heart.svg'*/}
        {/*    className={`svg-icon-2x svg-icon-gray-400 mt-1 text-${currentPathname === 'CHANGE_ME' ? 'primary' : 'gray-500'}`}*/}
        {/*  />*/}
        {/*</Link>*/}

        {/*<Link to="#" className="p-2 flex-fill text-center align-self-center cursor-pointer">*/}
        {/*  <KTSVG*/}
        {/*    path='/media/estay/messages.svg'*/}
        {/*    className={`svg-icon-2x svg-icon-gray-400 mt-1 text-${currentPathname === 'CHANGE_ME' ? 'primary' : 'gray-500'}`}*/}
        {/*  />*/}
        {/*</Link>*/}
      </div>
    </nav>
  )
}

export {FooterFixedGuests}
