import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'

const DefaultTitle: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, attributes} = useLayout()

  console.log({pageTitle, pageDescription, pageBreadcrumbs})
  return (
    <div {...attributes.pageTitle} className='page-title d-flex flex-column'>
      {/* begin::Title */}
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1 text-gray-700'>
            {Array.from(pageBreadcrumbs).map((item, index) => (
              <li className={'breadcrumb-item text-white opacity-75'} key={`${item.path}${index}`}>
                {!item.isSeparator ? (
                  <Link
                    className='text-gray-900 text-hover-primary'
                    to={item.path}
                    style={{ fontSize: 16 }}
                  >
                    {item.title}
                  </Link>
                ) : (
                  <span className='bullet bg-white opacity-75 w-5px h-2px'>
                    {item.title}
                  </span>
                )}
              </li>
            ))}
            <li
              className='text-gray-900 text-hover-primary'
              style={{ fontSize: 16 }}
            >
              {pageTitle}
            </li>
          </ul>
        )}
    </div>
  )
}

export {DefaultTitle}
