const QUERIES = {
  USERS_LIST: 'users-list',
  FETCH_SPACES: 'fetch-spaces',
  FETCH_SPACE: 'fetch-space',
  FETCH_APPLIANCE: 'fetch-appliance',

  FETCH_FAQS: 'fetch-faqs',
  FETCH_FAQ: 'fetch-faq',

  FETCH_ATTRIBUTES: 'fetch-attributes',
  FETCH_ATTRIBUTE: 'fetch-attribute',

  FETCH_SCANS: 'fetch-scans',
}

export {QUERIES}
