import {useAuth} from "../../modules/auth";
import {useEffect, useRef, useState} from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useNavigate } from 'react-router-dom'

const DashboardAffiliatePage = () => {
  const { currentAffiliate } = useAuth()
  const [hasCamera, setHasCamera] = useState(false)
  const [scanResult, setScanResult] = useState(null)
  const [hasPerformedScan, setHasPerformedScan] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!hasCamera && 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      setHasCamera(true)
    }
  }, [])

  const handleOpenCamera = () => {
    navigator && navigator.mediaDevices.getUserMedia({video: true})
  }

  let scanner: any = undefined

  useEffect(() => {
    if (!hasPerformedScan) {
      scanner = new Html5QrcodeScanner('reader', {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 30,
      }, false)

      scanner.render(success, error)

      setHasPerformedScan(true)
    }
  }, []);

  function success(result: any) {
    scanner.clear()
    setScanResult(result)
  }

  function error(error: any) {
    console.log('error =>', error)
  }

  useEffect(() => {
    if (!scanResult) return

    const url = (scanResult as string)?.split('house.estay.it')
    if (!url) return
    const [, path] = url
    navigate(path)
  }, [scanResult])


  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col'>
          <div className="card p-8">
            {/*<h1 className="mb-8 text-primary">*/}
            {/*  Benvenuti {currentAffiliate?.business_name}!*/}
            {/*</h1>*/}

            <h1 className="mb-8 text-primary text-center">
              Scansiona <br/> il QrCode dell'ospite
            </h1>


            {/*<p style={{ fontSize: 16 }}>*/}
            {/*  Ora che hai effettuato il login, scannerizza con la fotocamera di questo dispositivo*/}
            {/*  gli Estay City Pass che i tuoi clienti ti mostreranno al momento del pagamento.*/}
            {/*</p>*/}

            <div id={"reader"}></div>
          </div>
        </div>
      </div>
    </>
  );
}

const DashboardAffiliateWrapper = () => {
  return (
    <>
      <DashboardAffiliatePage />
    </>
  )
}

export {DashboardAffiliateWrapper}
