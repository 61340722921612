/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";

export function AsideMenuMain() {
  const intl = useIntl()
  const { currentProperty } = useAuth()

  return (
    <>
      <AsideMenuItem
        to={`/dashboard`}
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {currentProperty?.house_manual_visible ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.PROPERTY'})}
          </span>
            </div>
          </div>

          <AsideMenuItem
            to={`/p/${currentProperty?.property_id}/r`}
            icon='/media/icons/duotune/maps/map002.svg'
            title={intl.formatMessage({id: 'MENU.ACCESS'})}
            fontIcon='bi-layers'
          />

          <AsideMenuItem
            to={`/p/${currentProperty?.property_id}/e`}
            icon='/media/icons/duotune/general/gen007.svg'
            title={intl.formatMessage({id: 'MENU.EMERGENCIES'})}
            fontIcon='bi-layers'
          />

          <AsideMenuItem
            to={`/p/${currentProperty?.property_id}/s`}
            icon='/media/icons/duotune/general/gen030.svg'
            title={intl.formatMessage({id: 'MENU.SPACES'})}
            fontIcon='bi-layers'
          />
        </>
      ) : null}

      {currentProperty && (
        <AsideMenuItem
          to={`/p/${currentProperty?.property_id}/f`}
          icon='/media/icons/duotune/general/gen046.svg'
          title={intl.formatMessage({id: 'MENU.FAQ'})}
          fontIcon='bi-layers'
        />
      )}
    </>
  )
}
