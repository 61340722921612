import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {AffiliateCardReadingResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DASHBOARD_URL = `${API_URL}/affiliates/card`

const getReservationData = (reservationUuid: ID): Promise<AffiliateCardReadingResponse> => {
  return axios
    .get(`${DASHBOARD_URL}/${reservationUuid}`)
    .then((d: AxiosResponse<AffiliateCardReadingResponse>) => d.data)
}

export {getReservationData}
