import {Document} from "@react-pdf/renderer";
import React from "react";
import QRCodePage from "./QRCodePage";

function QRCodeDocument({ ids, url, text, subtext }: any) {
  return (
    <Document>
      {ids.map((id: any) => (
        <>
          <QRCodePage url={url} id={id} text={text} subtext={subtext} />
        </>
      ))}
    </Document>
  );
}

export default QRCodeDocument
