import {FC, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'
import axios, {AxiosResponse} from 'axios'

import {WithChildren} from '../helpers'
import { createContext } from 'react';
import {useAuth} from "../../app/modules/auth";
const initialContext = {
  language: 'it',
  getTranslation: (key: string, resource?: string): string => ''
}

const defaultState = {
  translationsSpaces: {},
  translationsFaqs: {},
  translationsAffiliates: {},
  translationsCategoriesSubcategories: {}
}

const I18nSpaceContext = createContext(initialContext);
const API_URL = process.env.REACT_APP_THEME_API_URL

const I18nSpaceProvider: FC<WithChildren> = ({children}) => {
  const [translationsSpaces, setTranslationsSpaces] = useState<any>(defaultState.translationsSpaces)
  const [translationsFaqs, setTranslationsFaqs] = useState<any>(defaultState.translationsFaqs)
  const [translationsAffiliates, setTranslationsAffiliates] = useState<any>(defaultState.translationsAffiliates)
  const [translationsCategoriesSubCategories, setTranslationsCategoriesSubCategories] = useState<any>(defaultState.translationsCategoriesSubcategories)

  const { locale } = useIntl()

  const { currentProperty } = useAuth()

  useEffect(() => {
    if (Object.keys(translationsSpaces).length === 0) {
      axios.get(`${API_URL}/languages/s/${locale}`).then((response: AxiosResponse) => {
        setTranslationsSpaces(response.data)
      })
    }

    if (Object.keys(translationsFaqs).length === 0) {
      axios.get(`${API_URL}/languages/f/${locale}`).then((response: AxiosResponse) => {
        setTranslationsFaqs(response.data)
      })
    }

    if (Object.keys(translationsAffiliates).length === 0) {
      axios.get(`${API_URL}/languages/a/${locale}`).then((response: AxiosResponse) => {
        setTranslationsAffiliates(response.data)
      })
    }

    if (Object.keys(translationsCategoriesSubCategories).length === 0) {
      axios.get(`${API_URL}/languages/cs/${locale}`).then((response: AxiosResponse) => {
        setTranslationsCategoriesSubCategories(response.data)
      })
    }
  }, [currentProperty, locale])

  const getTranslation = (key: string, resource = 'spaces') => {
    switch (resource) {
      case 'faq':
        return translationsFaqs[key] || '...'
      case 'spaces':
        return translationsSpaces[key] || '...'
      case 'affiliates':
        return translationsAffiliates[key] || '...'
      case 'categories_subcategories':
        return translationsCategoriesSubCategories[key] || '...'
      default:
        return translationsSpaces[key] || '...'
    }
  }



  return (
    <I18nSpaceContext.Provider value={{ language: locale, getTranslation }}>
      {children}
    </I18nSpaceContext.Provider>
  )
}

const useI18nSpaceContext = () => useContext(I18nSpaceContext)


export {I18nSpaceProvider, useI18nSpaceContext}
