import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardAffiliateWrapper} from "../pages/dashboard-affiliate/DashboardAffiliateWrapper";
import {AffiliateCardReadingWrapper} from "../pages/afiliate-card-reading/AffiliateCardReadingWrapper";
import {AffiliateHowItWorksWrapper} from "../pages/afiliate-how-it-works/AffiliateHowItWorksWrapper";

const PrivateRoutesAffiliate = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/dashboard' element={<DashboardAffiliateWrapper />} />
        <Route path='/come-funziona' element={<AffiliateHowItWorksWrapper />} />
        <Route path='/affiliates/card/:uuid' element={<AffiliateCardReadingWrapper />} />
        {/* Lazy Modules */}

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutesAffiliate}
