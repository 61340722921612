import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {DashboardDataResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DASHBOARD_URL = `${API_URL}/p`

const getDashboardData = (propertyId: ID): Promise<DashboardDataResponse> => {
  return axios
    .get(`${DASHBOARD_URL}/${propertyId}/d`)
    .then((d: AxiosResponse<DashboardDataResponse>) => d.data)
}

export {getDashboardData}
