/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useIntl} from "react-intl";
import clsx from "clsx";
import {setLanguage} from "../../../i18n/Metronici18n";

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-kingdom.svg'),
  },
  {
    lang: 'it',
    name: 'Italiano',
    flag: toAbsoluteUrl('/media/flags/italy.svg'),
  },
  // {
  //   lang: 'zh',
  //   name: 'Mandarin',
  //   flag: toAbsoluteUrl('/media/flags/china.svg'),
  // },
  // {
  //   lang: 'es',
  //   name: 'Spanish',
  //   flag: toAbsoluteUrl('/media/flags/spain.svg'),
  // },
  // {
  //   lang: 'ja',
  //   name: 'Japanese',
  //   flag: toAbsoluteUrl('/media/flags/japan.svg'),
  // },
  // {
  //   lang: 'de',
  //   name: 'German',
  //   flag: toAbsoluteUrl('/media/flags/germany.svg'),
  // },
  // {
  //   lang: 'fr',
  //   name: 'French',
  //   flag: toAbsoluteUrl('/media/flags/france.svg'),
  // },
]


const HeaderLanguagesMenu: FC = () => {
  const { locale } = useIntl()
  const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

  const currentLanguage = languages.filter(language => language.lang === locale)[0]
  return (
    <>
      <div
        className={clsx('cursor-pointer me-4', toolbarUserAvatarHeightClass)}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
      >
        <img
          className='h-40px w-40px'
          src={currentLanguage.flag}
          alt='estay'
        />
      </div>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </>

  )
}

export {HeaderLanguagesMenu}
